import { format } from 'date-fns';

export const formatDate = (dateString: string): string => {
  if (dateString == null) {
    return '-'
  }
  const dateParts = dateString.split('-');
  const date = new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]);
  return format(date, 'MMM dd, yyyy');
};
