import './App.css';
import Bio from './pages/bio';
import Home from './pages/home';
import React from 'react';
import Search from './pages/search';
import AlertDismissable from './pages/alert'
import Jumbotron from 'react-bootstrap/Jumbotron';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom';
import { Button, Nav, Navbar, NavItem } from 'react-bootstrap';
import { QueryClient, QueryClientProvider } from 'react-query'
import { Email } from "react-obfuscate-email";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const App = () => {
  const [searchText, setSearchText] = React.useState('');
  const [toSearchPage, setToSearchPage] = React.useState(false);

  const searchTextChanged = (event: any) => {
      // eslint-disable-next-line no-useless-escape
    setSearchText(event.target.value.replace(/[\\~\\-\\+\\:\\.\\^\\[\\]]/gm, ''));
    if ((event.key === 'Enter') && (event?.target?.value)) {
      setToSearchPage(true);
    } else {
      setToSearchPage(false);
    }
  }

  return (
    <div className="App">
      <AlertDismissable/>
      <QueryClientProvider client={queryClient}>
        <Router>
          {toSearchPage && <Redirect to={{pathname: "/search", state: {searchText}}}/>}
          <div>
            <Navbar expand="sm" sticky="top" id="main-navigation">
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" onClick={() => setToSearchPage(false)}>
                  <NavItem> <Link className="nav-link" to="/">Home</Link> </NavItem> 
                  <NavItem> <Link className="nav-link" to="/bio">Bio/Books</Link> </NavItem> 
                  <NavItem> <Email className="nav-link" email="contact.dougmcintosh.org@gmail.com">contact.dougmcintosh.org@gmail.com</Email> </NavItem>
                </Nav>
                <input type="text" placeholder="search..." className="mr-sm-2" onKeyUp={event => searchTextChanged(event)} />
                <Button onClick={() => setToSearchPage(true)}><i className="search fa fa-search"></i></Button>
              </Navbar.Collapse>
            </Navbar>

            <Jumbotron className='sky-and-trees' onClick={() => setToSearchPage(false)}>
              <h1 className='header text-center'>
                GLEANINGS
              </h1>
              <p className='text-center'>
                Bible study and theological resources for small groups and individuals, the products of a half century of study and ministry by Dr. Doug McIntosh
              </p>
            </Jumbotron>
            <Switch>
              <Route exact path='/' component={ Home }></Route>
              <Route path='/bio' component={ Bio }></Route>
              <Route path='/search' component={ Search } ></Route>
              <Route component={ Home } />
            </Switch>
          </div>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;