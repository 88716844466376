import '../App.css';
import React from 'react';
import { Accordion, Button, Card } from 'react-bootstrap';
import { allSermonData } from '../data/allSermonData';
import { Category, SubCategory } from '../models';
import { SermonSeries } from './sermonSeries';

const Home = () => {
  function sortBySequence([key1, category1]: [string, any], [key2, category2]: [string, any]) {
    if ((category1 as any).sequence < (category2 as any).sequence) {
      return -1;
    }
    if ((category1 as any).sequence > (category2 as any).sequence) {
      return 1;
    }
    return 0;
  }

  const extractSermonSeries = (subCategoryName: string, subCategory: SubCategory) => {
    return subCategory.seriesCollection
      .map((sermonSeriesData, index) => {
        return (
          <SermonSeries key={ subCategoryName + index} sermonSeriesData={sermonSeriesData}></SermonSeries>
        )
      })
  }

  const extractSubCategories = (categoryName: string, category: Category) => {
    return Object.entries(category.subCategories)
      .sort(sortBySequence)
      .map(([subCategoryName, subCategory]) => {
        return (
          <Accordion.Collapse key={subCategoryName} eventKey={ categoryName }>
            <Card.Body className="pt-2 pb-1 pl-2 pr-1">
              <span className="text-primary">{ subCategoryName }</span>
              { extractSermonSeries(subCategoryName, subCategory) }
            </Card.Body>
          </Accordion.Collapse>
        )
      })
  }

  const categories = Object.entries(allSermonData)
    .sort(sortBySequence)
    .map(([categoryName, category]) => {
      return (
        <Card key={categoryName}>
          <Card.Header className="text-center p-1">
            <Accordion.Toggle as={Button} className="text-dark text-center text-decoration-none" variant="link" eventKey={ categoryName }>
              { categoryName }
            </Accordion.Toggle>
          </Card.Header>
          { extractSubCategories(categoryName, category) }
        </Card>
      )
    })
 
  return (
    <div className="container-fluid">
      <Accordion>
        { categories }
      </Accordion>
    </div>
  );
}

export default Home;
