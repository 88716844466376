import '../App.css';
import React from 'react';
import soundIcon from '../images/sound.png';
import { Accordion, Button, Card } from 'react-bootstrap';
import { formatDate } from '../utils';
import { SermonSeriesData } from '../models';

export const SermonSeries = (props: any) => {
  const sermonSeriesData: SermonSeriesData = props.sermonSeriesData;
  const sermons = sermonSeriesData.sermons.map((sermon, index) => (
    <tr key={ sermon.title + index } className="row pl-0 pb-1">
      <td className="col-11 col-sm-11 col-md-7 pl-1 pr-1">
        <a href={ sermon.pdf } target='blank'>
          { sermon.title }
        </a>
      </td>
      <td className="col-0 col-sm-0 col-md-2 pl-1 pr-1">
        { sermon.passage }
      </td>
      <td className="col-0 col-sm-0 col-md-2 pl-1 pr-1 text-md-center">
        { formatDate(sermon.date) }
      </td>
      <td className="col-1 pl-1 pr-1 text-md-center">
        { sermon.audio &&
          <a href={ sermon.audio } target='blank'>
            <img className='sound' src={soundIcon} alt='listen'></img>
          </a>
        }
        { !sermon.audio && <span>-</span> }
      </td>
    </tr>
  ));

  const handleClick = (event: any) => {
    const classList = event.currentTarget.getElementsByTagName('i')[0].classList;
    if (classList.contains("fa-chevron-circle-down")) {
      classList.add('fa-chevron-circle-up');
      classList.remove('fa-chevron-circle-down');
    }
    else {
      classList.add('fa-chevron-circle-down');
      classList.remove('fa-chevron-circle-up');
    }
  }

  return (
    <Accordion>
      <Card className="border-0">
        <Card.Header className="pt-0 pb-1 pl-0 bg-white border-bottom-0">
          <Accordion.Toggle as={Button} className="indenter pl-3 pt-0 pb-0 pr-0 m-0 text-dark text-left text-decoration-none" onClick={handleClick}  variant="link" eventKey={ sermonSeriesData.seriesCode }>
            <i className="fa fa-chevron-circle-down"></i> {sermonSeriesData.seriesTitle}
          </Accordion.Toggle>
        </Card.Header>
        <Accordion.Collapse eventKey={ sermonSeriesData.seriesCode }>
          <Card.Body className="p-1 pb-1 pl-4">
            <table className={'table-striped table-hover w-100 ' + sermonSeriesData.seriesCode }>
              <tbody>
                {sermons}
              </tbody>
            </table>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default SermonSeries;
