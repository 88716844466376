import React from 'react';
import { Alert } from 'react-bootstrap';

class AlertDismissable extends React.Component<{}, {show: boolean}> {
  constructor(props: any, context: any) {
    super(props, context);

    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleShow = this.handleShow.bind(this);

    this.state = {
      show: true
    };
  }

  handleDismiss() {
    this.setState({ show: false });
  }

  handleShow() {
    this.setState({ show: true });
  }

  render() {
    if (this.state.show) {
      return (
        <Alert variant='warning' onClose={this.handleDismiss} dismissible>
          <b>9 July 2024: Old Testament/First and Second Kings/Elijah series added.</b>
        </Alert>
      );
      /*
      return (
        <Alert variant='warning' onClose={this.handleDismiss} dismissible>
          <h4>Oh snap! You got an error!</h4>
          <p>
            Change this and that and try again. Duis mollis, est non commodo
            luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit.
            Cras mattis consectetur purus sit amet fermentum.
          </p>
        </Alert>
      );
      */
    } else {
      return '';
    }
  }
}

//render(<AlertDismissable />);

export default AlertDismissable;