import '../App.css';
import { Container, Row } from 'react-bootstrap';
import { formatDate } from '../utils';
import React from 'react';
import soundIcon from '../images/sound.png';
import { useQuery } from 'react-query'
import DOMPurify from "dompurify";
import parse from "html-react-parser";

interface Hit {
  sermonAudio: string;
  sermonManuscript: string;
  sermonTitle: string;
  fragment: string;
  sermonDate: string;
  sermonPassage: string;
};

interface SearchResponse {
  message: string;
  results: Hit[];
};

const noMatchesFound = (
  <Row key='matches'>
    <table className={'table-striped table-hover w-100'}>
      <tbody>
        <tr className="row ml-2 pr-0 mr-0 pb-1">
          <td className="col-0 col-sm-0 col-md-2 pl-1 pr-1">
            No matching results.
          </td>
        </tr>
      </tbody>
    </table>
  </Row>
);

const messageTable = (message: string) => {
  return (
    <div>
      <Container>
        <Row key="message">
          <p className="pl-2 mb-0">
            <span>{message}</span>
          </p>
        </Row>
      </Container>
    </div>
  );
}

const matchingRows = (searchResponse: SearchResponse) => {
  if (searchResponse.results.length > 0) {
    const sermons = searchResponse.results.map((hit: Hit, index) => {
      const sanitizedHit = DOMPurify.sanitize(hit.fragment, {
        USE_PROFILES: { html: true },
      });

      return (
        <tr key={ hit.sermonTitle + index } className="row ml-2 pr-0 mr-0 pb-1">
          <td className="col-11 col-sm-11 col-md-6 pl-1 pr-1">
            { parse(sanitizedHit) }
          </td>
          <td className="col-0 col-sm-0 col-md-3 pl-1 pr-1">
            <a href={ hit.sermonManuscript } target='blank'>
              { hit.sermonTitle }
            </a>
            <br/>({formatDate(hit.sermonDate)})
          </td>
          <td className="col-0 col-sm-0 col-md-2 pl-1 pr-1 text-md-center">
            { hit.sermonPassage }            
          </td>
          <td className="col-1 pl-1 pr-1 col-md-1 text-md-center">
            { hit.sermonAudio &&
              <a href={ hit.sermonAudio } target='blank'>
                <img className='sound' src={soundIcon} alt='listen'></img>
              </a>
            }
            { !hit.sermonAudio && <span>-</span> }
          </td>
        </tr>
      )
    })

    return (
      <Row key='matches'>
        <table className={'table-striped table-hover w-100'}>
          <tbody>
            <tr className="row ml-2 pr-0 mr-0 pb-1">
              <td className="col-11 col-sm-11 col-md-6 pl-1 pr-1 bolded">
                Matching Text
              </td>
              <td className="col-0 col-sm-0 col-md-3 pl-1 pr-1 bolded">
                Sermon (Date)
              </td>
              <td className="col-0 col-sm-0 col-md-2 pl-1 pr-1 text-md-center bolded">
                Passage
              </td>
              <td className="col-1 pl-1 pr-1 col-md-1 text-md-center bolded">
                Audio
              </td>
            </tr>
            {sermons}
          </tbody>
        </table>
      </Row>
    )
  } else {
    return messageTable("");
  }
};

async function remoteSearch(searchQuery:string):Promise<SearchResponse> {
  // async function remoteSearch():Promise<SearchResponse> {
  // const searchQuery:string = 'baptism';
  console.log("Calling search endpoint with query: \"" + searchQuery + "\"");
  
  try {
    const url = 'https://954thr58jh.execute-api.us-east-1.amazonaws.com/search?q=' + searchQuery
    //const url = 'http://localhost:8080/search?q=' + searchQuery
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }

    return (await response.json()) as SearchResponse;
    //const tmpSearchResponse = (await response.json()) as SearchResponse;
    //console.log('search response: ', JSON.stringify(result, null, 4));
  } catch (error) {
    if (error instanceof Error) {
      console.log('error: ', error.message);
    } else {
      console.log('unexpected error: ', error);
    }
    throw new Error("Search request failed with error.");
  }
}

const Search = (props: any) => {
  const searchText = props.location.state.searchText;

  let { isLoading, isError, data/*, error */ } = useQuery(
    ["search", searchText], 
    () => remoteSearch(searchText),
    {retry: false});

  if (isLoading) {
    return messageTable(`Searching manuscripts for "${searchText}" ...`);
  }
  if (isError) {
    return messageTable("Error executing manuscript search!");
  }

  if (!data) {
    console.warn("Artificially setting response data to empty.");
    return noMatchesFound;
  } else {
    return (
      <div>
        <Container>
          <Row key="search-results" className="padded-row">
            <p className="pl-2 mb-0 bolded">
              <span>Displaying { data.results.length } search results for: "{ searchText }"</span>
            </p>
          </Row>

          { matchingRows(data) }
        </Container>
      </div>
    );
  }
}

export default Search;