import '../App.css';
import bookGodUpClose from '../images/bookGodUpClose.jpg';
import bookHolmanOTDeuteronomy from '../images/bookHolmanOTDeuteronomy.jpg';
import bookLifesGreatestJourney from '../images/bookLifesGreatestJourney.jpg';
import bookLoveFullCircle from '../images/bookLoveFullCircle.jpg';
import bookTheWarWithinYou from '../images/bookTheWarWithinYou.jpg';
import dougMcIntosh from '../images/doug-mcintosh.png';
import React from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';

const Bio = () => {
  return (
    <div>
      <Container>
        <Row id='bio'>
          <p>
            <img src={ dougMcIntosh } alt="Doug McIntosh" />
            Doug McIntosh held graduate degrees from Dallas Theological Seminary (ThM, Semitic Languages and Old Testament Exegesis) and Trinity Evangelical Divinity School (Doctor of Ministry). Since 1967, he served at various times as a church planter, senior pastor, seminary instructor, Bible translation exegetical consultant, author, conference speaker, and Bible teacher. Pastor Doug, as he was affectionately known, died on April 9, 2021.
          </p>
        </Row>

        <Row>
          <Col sm className="d-flex justify-content-center px-1 pb-4">
            <Card style={{ width: '16rem' }}>
              <Card.Img variant="top" src={ bookGodUpClose } />
              <Card.Body>
                <Card.Text className="text-center">
                  <a href="https://www.amazon.com/God-Up-Close-Meditate-Word/dp/0802470793">God Up Close</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm className="d-flex justify-content-center px-1 pb-4">
            <Card style={{ width: '16rem' }}>
              <Card.Img variant="top" src={ bookLoveFullCircle } />
              <Card.Body>
                <Card.Text className="text-center">
                  <a href="https://www.amazon.com/Love-Full-Circle-Golden-Foundations/dp/0802466478">Love Full Circle</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm className="d-flex justify-content-center px-1 pb-4">
            <Card style={{ width: '16rem' }}>
                <Card.Img variant="top" src={ bookLifesGreatestJourney } />
              <Card.Body>
                <Card.Text className="text-center">
                  <a href="https://www.amazon.com/Lifes-Greatest-Journey-heavenly-earthly/dp/0802466486">Life's Greatest Journey</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm className="d-flex justify-content-center px-1 pb-4">
            <Card style={{ width: '16rem' }}>
              <Card.Img variant="top" src={ bookTheWarWithinYou } />
              <Card.Body>
                <Card.Text className="text-center">
                  <a href="https://www.amazon.com/War-Within-You-Overcoming-Obstacles/dp/0802466494">The War Within You</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col sm className="d-flex justify-content-center px-1 pb-4">
            <Card style={{ width: '16rem' }}>
              <Card.Img variant="top" src={ bookHolmanOTDeuteronomy } />
              <Card.Body>
                <Card.Text className="text-center">
                  <a href="https://www.amazon.com/Deuteronomy-Holman-Old-Testament-Commentary/dp/0805494634">Holman Old Testament Commentary: Volume 3, Deuteronomy</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Bio;
